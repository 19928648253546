import { Dispatch } from 'react';

export interface IReduxType {
  Pending: string;
  Success: string;
  Failed: string;
}

export enum StateStatus {
  Success = 'Success',
  Pending = 'Pending',
  Failed = 'Failed',
}

export interface IAction {
  type: string;
  status?: StateStatus;
  data: any;
}

export const typeCreator = (
  reducerName: string,
  label: string
): IReduxType => ({
  Pending: `[${reducerName}][${label}][Pending]`,
  Success: `[${reducerName}][${label}][Success]`,
  Failed: `[${reducerName}][${label}][Failed]`,
});

export const typeCreatorV2 = (reducerName: string, label: string): string =>
  `[${reducerName}][${label}]`;

export interface IActionMethods {
  onSuccess(result?: any): IAction;
  onFailed(result?: any): IAction;
  onPending(result?: any): IAction;
  action(
    data?: any
  ): ((dispatch: Dispatch<any>, getState?: () => any) => void) | IAction;
}

export const KEEP_THE_SAME = '__KEEP_THE_SAME__';
