import { useSelector } from 'react-redux';
import { ReducerKeys } from '../../config';
import { ThemesModeEnum } from 'config';

export const useSelectTheme = (): ThemesModeEnum => {
  return useSelector(
    (state: any) => state[ReducerKeys.THEME_REDUCER].themeMode
  );
};

export const useIsMobileAppAccess = (): boolean => {
  return useSelector(
    (state: any) => state[ReducerKeys.THEME_REDUCER].accessFromMobileApp
  );
};
